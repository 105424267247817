<template>
    <div class="mt-3 ml-0">
        <v-sheet class="dense-inputs">
            <v-row no-gutters dense>
                <v-col class="d-flex flex-row float-right cols-xs-12 col-lg-6">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left filter-width-250"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <v-spacer/>
                    <ExportTableSalesCommission
                        :export-conditions="exportConditions"
                        :export-data="paidOutItems"
                        :export-fields="headers"
                        :export-name="'paid-out'"
                        class="mr-3"
                    ></ExportTableSalesCommission>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loadingItems"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,75,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :items="paidOutItems"
            :search="searchTerm"
            :options.sync="tableOptions"
            calculate-widths
            class="pt-0 pl-0 appic-table-light specification-table col-xs-12 col-lg-6"
            dense
            id="intakeTable"
            item-key="index"
            ref="commissionPaidOutList"
            :sort-by="'PaidOut.commissionMonthString'"
            :sort-desc="true"
            multi-sort
        >
            <template v-slot:body.prepend>
                <tr>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ $t('message.totals') }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left">USD</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm" style="text-align: right !important;">{{ formatThisNumber(totals.paidOutAmount,'0,0.00') }}</th>
                </tr>
            </template>
            <template v-slot:item.PaidOut.amount="{item}">
                <div class="text-no-wrap text-right" v-if="item.PaidOut.amount != null">
                    {{ formatThisNumber(item.PaidOut.amount, '0,0.00' ) }}
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {formatDate, numberFormat} from "Helpers/helpers";
import {mapFields} from "vuex-map-fields";

const ExportTableSalesCommission = () => import("Components/Appic/ExportTableSalesCommission");

export default {
    name: "CommissionPaidOutList",
    components: { ExportTableSalesCommission },
    props: ['loadingItems','paidOutItems','commission-paid-out'],
    data() {
        return {
            exportParams: [],
            loading: {
                filterResults: false
            },
            searchTerm: null,
            tableOptions: {
                page: -1
            },
            totals: {
                paidOutAmount: 0
            }
        }
    },
    computed: {
        ...mapFields('commission',{
            exportConditions: 'exportConditions'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        exportFileName() {
            return 'intake-list.xls'
        },
        exportFileFields() {
            let fields = {}
            this.headers.forEach(header => {
                if(header.export) {
                    fields[header.text] = header.value
                }
            })
            return fields
        },
        formatDate: () => formatDate,
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.monthYearCommission'),
                    value: 'PaidOut.commissionMonthString',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: true,
                    export: true
                },
                {
                    id: 2,
                    text: this.$t('message.monthYearPaid'),
                    value: 'PaidOut.paidMonthString',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: true,
                    export: true
                },
                {
                    id: 3,
                    text: this.$t('message.country'),
                    value: 'PaidOut.country',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: true,
                    export: true
                },
                {
                    id: 4,
                    text: this.$t('message.salesColleague'),
                    value: 'PaidOut.salesContact',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: true,
                    export: true
                },
                {
                    id: 5,
                    text: this.$t('message.currency'),
                    value: 'PaidOut.currency',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 5,
                    text: this.$t('message.paidOutAmount'),
                    value: 'PaidOut.amount',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false,
                    export: true
                }
            ]
        }
    },
    methods: {
        computeTotals() {
            let _this = this
            this.$nextTick().then(function () {
                _this.totals.paidOutAmount = 0

                let items = _this.$refs.commissionPaidOutList.$children[0].filteredItems
                items.forEach((item) => {
                    _this.totals.paidOutAmount += item.PaidOut.amount
                })
            })
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
    },
    watch: {
        searchTerm() {
            this.computeTotals()
        }
    },
    mounted(){
        this.computeTotals()
    }
}
</script>

<style>
.filter-width-250 {
    width: 250px !important;
    max-width: 250px !important;
}
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>